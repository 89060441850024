<template>
	<div>
		<b-tabs vertical content-class="col-12 col-md-10 mt-1 mt-md-0" pills nav-wrapper-class="col-md-2 col-12" nav-class="nav-left" lazy>
			<b-tab title="Workers">
				<Workers />
			</b-tab>
			<!-- <b-tab title="Payments">
				<Payments />
			</b-tab> -->
		</b-tabs>
	</div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
// import Payments from './payments/Index.vue';
import Workers from './Workers.vue';

export default {
	name: 'WorkerIndex',

	components: {
		BTabs,
		BTab,
		Workers,
		// Payments,
	},
};
</script>

<style lang="scss" scoped></style>
