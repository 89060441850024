<template>
	<div>
		<base-table :headers="headers" :items="workers" paginate table-top>
			<template #action>
				<b-button variant="primary" :to="{ name: 'project.cfw.sub-project.new-payment' }">Add Payment</b-button>
			</template>
			<template #cell(actions)="data">
				<div class="text-nowrap">
					<b-button variant="outline-secondary" class="cursor-pointer" size="sm" @click="showWorkerInfo(data.item)">
						<span class="align-middle">View </span>
					</b-button>
				</div>
			</template>
		</base-table>

		<b-modal v-model="workerInfo" :title="modalTitle" size="lg" hide-footer centered>
			<Details :worker="currentWorker" />
		</b-modal>
	</div>
</template>

<script>
import store from '@/store';
import { BButton, BModal } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import Details from './Details.vue';

export default {
	name: 'WorkerIndex',

	components: {
		BButton,
		BModal,
		BaseTable,
		Details,
	},

	data: () => ({
		headers: [
			{ key: 'id', label: 'ID', sortable: true },
			{ key: 'full_name', sortable: true },
			{ key: 'phone', sortable: true },
			{ key: 'identity_type', sortable: true },
			{ key: 'identity_number', sortable: true },
			{ key: 'gender' },
			{ key: 'actions' },
		],

		workers: [],

		workerInfo: false,
		currentWorker: undefined,
	}),

	computed: {
		modalTitle() {
			return this.currentWorker?.full_name;
		},
	},

	created() {
		this.fetchWorkers();
	},

	methods: {
		showWorkerInfo(worker) {
			this.currentWorker = worker;
			this.workerInfo = true;
		},

		async fetchWorkers() {
			try {
				this.workers = await store.dispatch('workers/getWorkers', this.$route.params.subId);
			} catch (error) {
				console.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
